<template>
  <CHeader fixed with-subheader light>
    <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderNav class="mr-auto">
      <BreadcrumbRouter class="border-0 mb-0"/>
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-auto">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import BreadcrumbRouter from "@/components/BreadcrumbRoute";

export default {
  name: 'TheHeader',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  components: {
    BreadcrumbRouter,
    TheHeaderDropdownAccnt
  }
}
</script>
