<template>
  <div class="c-app" >
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>

import TheSidebar from "@/containers/TheSidebar";
import TheHeader from "@/containers/TheHeader";
import TheFooter from "@/containers/TheFooter";
export default {
  name: 'TheContainer',
  mixins: [],
  components: {
    TheFooter,
    TheHeader,
    TheSidebar
  },
  data() {
    return {
      showSocialModal: false,
      formData: {
        name: '',
        phone: '',
        password: '',
        password_confirmation: '',
        terms: false,
        policy: false,
      },
    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
